import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <>
      <div className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>A Qualidade da Dorman se encontra aqui</h2>
            <br />
            <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'space-between' }}>
              <img style={{ borderRadius: '1rem' }} width={350} src="img/dorman1.jpg" />
              <img style={{ borderRadius: '1rem' }} width={350} src="img/dorman2.jpg" />
            </div>
          </div>
        </div>
      </div>
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Peças</h2>
            <p>
              Alguns dos nossos produtos
            </p>
          </div>
          <div className="row">
            <div className="portfolio-items">
              {props.data
                ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
                : "Loading..."}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
